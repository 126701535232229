import React, { useState } from "react";
import Header from "../../Component/Header/index";
import Footer from "../../Component/Footer";
import { Container } from "react-bootstrap";
import Section from "../../Component/Section";
import CategoryProduct from "../../Component/CategoryProduct";
import HomeProduct from "../../Component/HomeProduct";
import { Breadcrumb } from "antd";
import "./index.css";
export default function Index() {
  return (
    <Container fluid style={{ padding: "0px", margin: "0px" }}>
      <Header />
      <div>
        <Container fluid className="section_background_image"></Container>
        <Container className="section_transparent" fluid>
          <Container style={{ height: "100%", display: "flex" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Breadcrumb
                items={[
                  {
                    title: (
                      <a href={"/"} className={"breadcrum_parent_menu"}>
                        Home
                      </a>
                    ),
                  },

                  {
                    title: (
                      <p className="breadcrum_active">{"Happy New Year"}</p>
                    ),
                  },
                ]}
              />
            </div>
          </Container>
        </Container>
      </div>
      <Container style={{ marginTop: 250 }}>
        <HomeProduct
          title={"Happy New Year"}
          description={"Celebrate New Year With Us"}
        />
      </Container>
      <Footer />
    </Container>
  );
}
