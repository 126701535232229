import React, { useState, useEffect } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./index.css";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
  DatePicker,
  TimePicker,
  InputNumber,
  Checkbox,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { country } from "../../raw/country";
import { falconApiCalling } from "../../Component/API/falconApiCalling";
import { BrowserView, MobileView } from "react-device-detect";
export default function Index() {
  const [data, setData] = useState([]);
  const [payurl, setPayurl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [countryList, setCountryList] = useState(
    country.map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    })
  );

  const getLocalCart = () => {
    let localData = localStorage.getItem("falcon_cart");
    if (localData === null) {
      console.log("cart yes");
    } else {
      let d = JSON.parse(localData);
      setData(d);
    }
  };
  const calculateAmount = (
    numAdult,
    numChild,
    adultPrice,
    childPrice,
    transport
  ) => {
    let adultTotal = parseInt(numAdult) * parseInt(adultPrice);
    let childTotal = parseInt(numChild) * parseInt(childPrice);
    let total = adultTotal + childTotal + transport;

    return total;
  };
  const getTotal = () => {
    let localData = localStorage.getItem("falcon_cart");
    let data = JSON.parse(localData);
    const adultSubtotal = data.reduce((acc, booking) => {
      return acc + booking.adult * booking.type.adult;
    }, 0);

    const childSubtotal = data.reduce((acc, booking) => {
      return acc + booking.child * booking.type.child;
    }, 0);

    const transportCharges = data.reduce((acc, booking) => {
      return acc + booking.transport;
    }, 0);

    const addOnCharges = data.reduce((totalAddOnCost, item) => {
      if (Array.isArray(item.addOn) && item.addOn.length > 0) {
        const totalPeople = item.adult + item.child;
        const addOnCost = item.addOn.reduce((total, addOnItem) => {
          if (addOnItem.type === "Per Pax") {
            return total + totalPeople * parseFloat(addOnItem.price);
          } else {
            return total + parseFloat(addOnItem.price);
          }
        }, 0);
        return totalAddOnCost + addOnCost;
      }
      return totalAddOnCost;
    }, 0);
    const totalCost =
      adultSubtotal + childSubtotal + transportCharges + addOnCharges;
    return totalCost;
  };
  useEffect(() => {
    getLocalCart();
  }, []);

  const onFinish = (values) => {
    setIsLoading(true);
    let pickupV;
    if (values.pickup === undefined) {
      pickupV = "";
    } else {
      pickupV = values.pickup;
    }
    let add = values.street_address.replace(",", "");
    let address = add.replace(" ", "");
    console.log("values======>", values);
    let first_name = values.first_name;
    let last_name = values.last_name;
    let country = values.country;
    let street_address = address.replace("'", "");
    let town = values.town;
    let phone = values.phone;
    let email = values.email;
    let pickup = pickupV;
    let PAYMENT_URL = "https://business.mamopay.com/manage_api/v1/links";
    let SANDBOX_URL =
      "https://sandbox.dev.business.mamopay.com/manage_api/v1/links";
    let SUCCESS_SAND = "http://localhost:3000/success/";
    let FAILED_SAND = "http://localhost:3000/failed/";
    let SUCCESS_PRODUCTION = "https://aladdinadventuretours.com//success/";
    let FAILED_PRODUCTION = "https://aladdinadventuretours.com/failed";
    let params = {
      action: "GET_PAYMENT_URL",
      firstname: first_name,
      lastname: last_name,
      email: email,
      total: getTotal(),
      country: country,
      street_address: street_address,
      town: town,
      phone: phone,
      pickup: pickup,
      url: PAYMENT_URL,
      success: SUCCESS_PRODUCTION,
      failed: FAILED_PRODUCTION,
    };
    falconApiCalling(params).then((res) => {
      console.log(res);
      setIsLoading(false);
      setPayurl(res.payment_url);

      class MamoPay {
        constructor() {
          this.consumerUrl = encodeURIComponent(window.location.href);
        }
        getDomain(url) {
          const parts = url.split("/");
          if (url.startsWith("http://")) {
            return parts[0] + "//" + parts[2];
          } else if (url.startsWith("https://")) {
            return parts[0] + "//" + parts[2];
          } else {
            return null;
          }
        }
        addIframeToWebsite(elementID, paymentLinkUrl) {
          const element = document.getElementById(elementID);
          if (element === null) {
            console.error(
              `Failed to locate element with ID ${elementID} from the DOM`
            );
            return;
          }
          const iframe = document.createElement("iframe");
          const domainName = this.getDomain(paymentLinkUrl);
          iframe.src = `${paymentLinkUrl}?consumer=${this.consumerUrl}`;
          iframe.setAttribute("crossorigin", "anonymous");
          iframe.setAttribute("id", "iframe-mamo-checkout");
          iframe.setAttribute("allowTransparency", "true");
          iframe.setAttribute("allow", "payment");
          iframe.allowTransparency = "true";
          iframe.style.backgroundColor = "transparent";
          iframe.style.width = "100%";
          iframe.style.height = "100%";
          iframe.style.border = "0";
          iframe.style.display = "block";
          iframe.style.zIndex = 99999;
          element.appendChild(iframe);
          iframe.onload = function () {
            window.addEventListener("message", (event) => {
              const iframe = document.getElementById("iframe-mamo-checkout");
              if (event.origin !== domainName) {
                return;
              }
              if (
                event.data === "closeIframe" ||
                event.message === "closeIframe"
              ) {
                iframe.style.display = "none";
              }
              if (
                event.data === "checkout-complete" ||
                event.message === "checkout-complete"
              ) {
                iframe.style.display = "none";
              }
              if (
                event.data === "connectionEstablished" ||
                event.message === "connectionEstablished"
              ) {
                console.log("connection establised");
              }
              if (
                event.data.type &&
                event.data.type === "confirmation_required"
              ) {
                iframe.style.display = "none";
                window.location.replace(event.data.payload);
              }
            });
          };
        }
      }
      window.onload = () => {
        const checkoutDiv = document.getElementById("mamo-checkout");
        if (checkoutDiv !== null) {
          const mamoPay = new MamoPay();
          mamoPay.addIframeToWebsite(
            "mamo-checkout",
            checkoutDiv.getAttribute("data-src")
          );
        }
      };
      const mamoPay = new MamoPay();
      mamoPay.addIframeToWebsite("mamo-checkout-element", res.payment_url);
    });
  };

  return (
    <Container fluid style={{ padding: "0px" }}>
      <Header />
      <Container fluid className="checkout_breadcrumb_section">
        <h3 className="checkout_header_title text-center">Checkout</h3>
      </Container>
      <Container className="checkout_main">
        <Row>
          <Col md={8} style={{ minHeight: "1200px" }}>
            <BrowserView>
              <div className="checkout_form_header">
                <h2 class="checkout-heading-title text-center">Checkout</h2>
                <div className="checkout_header_login_section">
                  <div className="checkout_header_login_text">
                    <span className="checkout_returning_customer">
                      Returning customer?
                    </span>{" "}
                    <a href={"/login"} className="checkout_login_text">
                      Click here to login
                    </a>
                  </div>
                </div>
              </div>
              <div className="checkout_form_container">
                <Container style={{ padding: "10px" }}>
                  <div>
                    <Form
                      name="complex-form"
                      layout="vertical"
                      onFinish={onFinish}
                      labelCol={{
                        span: 12,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <Form.Item
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "First Name is required",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                          }}
                        >
                          <Input
                            placeholder={"First Name"}
                            style={{ height: "40px" }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "Last Name is required",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                            margin: "0 8px",
                          }}
                        >
                          <Input
                            placeholder={"Last Name"}
                            style={{ height: "40px" }}
                          />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label="Country/Region"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Country is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Select
                          options={countryList}
                          showSearch
                          style={{
                            height: "40px",
                            borderColor: "#c7c7c7",
                            borderWidth: "0.6px",
                            border: "0.6px solid #c7c7c7",
                            borderRadius: "10px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Street Address"
                        name="street_address"
                        rules={[
                          {
                            required: true,
                            message: "Street Address is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Street Address"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Town / City"
                        name="town"
                        rules={[
                          {
                            required: true,
                            message: "Town is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Town / City"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Phone Number"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email address is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Email Address"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Pickup Location (Required Only If You Select Transportation Service)"
                        name="pickup"
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Pickup Location"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Special Request For Booking"
                        name="request"
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Special Request For Booking"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Please select agreement")
                                  ),
                          },
                        ]}
                        // {...tailFormItemLayout}
                      >
                        <Checkbox>
                          have read and agree to the website{" "}
                          <a href="">terms & condition</a>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="promotion"
                        valuePropName="checked"

                        // {...tailFormItemLayout}
                      >
                        <Checkbox>
                          I want to receive updates about products and
                          promotions.
                        </Checkbox>
                      </Form.Item>
                      <Form.Item style={{ width: "100%" }}>
                        {isLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 48,
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="checkout_btn"
                            style={{ width: "100%" }}
                          >
                            Checkout
                          </Button>
                        )}
                      </Form.Item>
                    </Form>
                    <div
                      id="mamo-checkout-element"
                      data-src={payurl}
                      style={{ height: "600px" }}
                    />
                  </div>
                </Container>
              </div>
            </BrowserView>
            <MobileView>
              <div className="checkout_form_header">
                <h2 class="checkout-heading-title text-center">Checkout</h2>
                <div className="checkout_header_login_section">
                  <div className="checkout_header_login_text">
                    <span className="checkout_returning_customer">
                      Returning customer?
                    </span>{" "}
                    <a href={"/login"} className="checkout_login_text">
                      Click here to login
                    </a>
                  </div>
                </div>
              </div>
              <div className="checkout_form_container">
                <Container style={{ padding: "10px" }}>
                  <div>
                    <Form
                      name="complex-form"
                      layout="vertical"
                      onFinish={onFinish}
                      labelCol={{
                        span: 12,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <Form.Item
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "First Name is required",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                          }}
                        >
                          <Input
                            placeholder={"First Name"}
                            style={{ height: "40px" }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "Last Name is required",
                            },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                            margin: "0 8px",
                          }}
                        >
                          <Input
                            placeholder={"Last Name"}
                            style={{ height: "40px" }}
                          />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label="Country/Region"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Country is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Select
                          options={countryList}
                          showSearch
                          style={{
                            height: "40px",
                            borderColor: "#c7c7c7",
                            borderWidth: "0.6px",
                            border: "0.6px solid #c7c7c7",
                            borderRadius: "10px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Street Address"
                        name="street_address"
                        rules={[
                          {
                            required: true,
                            message: "Street Address is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Street Address"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Town / City"
                        name="town"
                        rules={[
                          {
                            required: true,
                            message: "Town is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Town / City"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Phone Number"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Phone Number"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email address is required",
                          },
                        ]}
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Email Address"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Pickup Location (Required Only If You Select Transportation Service)"
                        name="pickup"
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Pickup Location"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Special Request For Booking"
                        name="request"
                        style={{
                          display: "inline-block",
                          width: "calc(100%)",
                        }}
                      >
                        <Input
                          placeholder="Special Request For Booking"
                          style={{ height: "40px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("Please select agreement")
                                  ),
                          },
                        ]}
                        // {...tailFormItemLayout}
                      >
                        <Checkbox>
                          have read and agree to the website{" "}
                          <a href="">terms & condition</a>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="promotion"
                        valuePropName="checked"

                        // {...tailFormItemLayout}
                      >
                        <Checkbox>
                          I want to receive updates about products and
                          promotions.
                        </Checkbox>
                      </Form.Item>
                      <Form.Item style={{ width: "100%" }}>
                        {isLoading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 48,
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="checkout_btn"
                            style={{ width: "100%" }}
                          >
                            Checkout
                          </Button>
                        )}
                      </Form.Item>
                    </Form>
                    <div
                      id="mamo-checkout-element"
                      data-src={payurl}
                      style={{ height: "600px" }}
                    />
                  </div>
                </Container>

                <Container className="container_checkout_item">
                  {data.map((item) => {
                    return (
                      <Container
                        className="cart_mainContainer"
                        style={{
                          background: "transparent",
                          padding: "0px",
                          border: "none",
                          marginTop: "0px",
                          borderColor: "transparent",
                          borderRadius: "0px",
                          borderTopColor: "transparent",
                        }}
                      >
                        <Row>
                          <Col md={4} className="cart-product-img-holder">
                            <Image
                              src={item.image}
                              className="cart-product-image"
                            />
                          </Col>
                          <Col md={8}>
                            <br></br>

                            <span className="cart-product-title">
                              {item.pname}
                            </span>
                            <br></br>
                            <span className="cart-product-normal">
                              Adult: {item.adult}
                            </span>
                            <br></br>
                            <span className="cart-product-normal">
                              Child: {item.child}
                            </span>
                            <br></br>
                            <span className="cart-product-normal">
                              Add On:{" "}
                              {Array.isArray(item.addOn) ? (
                                <>
                                  {item.addOn.map((data) => {
                                    return data.label + ", ";
                                  })}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </span>
                            <br></br>
                            <span className="cart-product-normal">
                              Transport: {item.transport_type}
                            </span>
                            <br></br>
                            <span className="cart-product-normal">
                              Transport Charges: AED {item.transport}
                            </span>
                            <br></br>
                            <span className="cart-product-normal">
                              Booking Date: {item.date}
                            </span>
                            <br></br>
                            <br></br>
                          </Col>
                        </Row>
                      </Container>
                    );
                  })}
                  <Row style={{ marginTop: "40px" }}>
                    <Col ms={6} className="cart_total_div">
                      <span className="cart_total_text">Subtotal</span>
                    </Col>
                    <Col
                      ms={6}
                      className="cart_total_div"
                      style={{ justifyContent: "end" }}
                    >
                      <span className="cart_total_text">AED {getTotal()}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col ms={6} className="cart_total_div">
                      <span className="cart_total_text">Total</span>
                    </Col>
                    <Col
                      ms={6}
                      className="cart_total_div"
                      style={{ justifyContent: "end" }}
                    >
                      <span className="cart_total_text">AED {getTotal()}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col ms={6} className="cart_total_div">
                      <span className="cart_total_text">To Pay </span>
                    </Col>
                    <Col
                      ms={6}
                      className="cart_total_div"
                      style={{ justifyContent: "end" }}
                    >
                      <span className="cart_total_text">
                        <strong>AED {getTotal()}</strong>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </MobileView>
          </Col>
          <Col md={4} style={{ minHeight: "1200px" }}>
            <BrowserView>
              {/* <Container className="coupon_container_checkout">
                <div style={{ width: "90%", marginLeft: "5%" }}>
                  <div className="coupon_header">
                    <h3 className="coupon_heading text-start">Apply Coupon</h3>
                  </div>
                  <div
                    className="coupon_input_holder"
                    style={{
                      background: "#FFF",
                      width: "100%",
                      marginLeft: "0px",
                      marginTop: "20px",
                    }}
                  >
                    <input className="coupon_input" placeholder="Coupon Code" />
                    <Button className="apply_coupon_button">Apply</Button>
                  </div>
                  <p className="checkout_coupon_text">
                    Apply Coupon code for get discount. Collect Coupon by
                    shopping more.
                  </p>
                </div>
              </Container> */}
              <Container className="container_checkout_item">
                {data.map((item) => {
                  return (
                    <Container
                      className="cart_mainContainer"
                      style={{
                        background: "transparent",
                        padding: "0px",
                        border: "none",
                        marginTop: "0px",
                        borderColor: "transparent",
                        borderRadius: "0px",
                        borderTopColor: "transparent",
                      }}
                    >
                      <Row>
                        <Col md={4} className="cart-product-img-holder">
                          <Image
                            src={item.image}
                            className="cart-product-image"
                          />
                        </Col>
                        <Col md={8}>
                          <br></br>

                          <span className="cart-product-title">
                            {item.pname}
                          </span>
                          <br></br>
                          <span className="cart-product-normal">
                            Adult: {item.adult}
                          </span>
                          <br></br>
                          <span className="cart-product-normal">
                            Child: {item.child}
                          </span>
                          <br></br>
                          <span className="cart-product-normal">
                            Add On:{" "}
                            {Array.isArray(item.addOn) ? (
                              <>
                                {item.addOn.map((data) => {
                                  return data.label + ", ";
                                })}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </span>
                          <br></br>
                          <span className="cart-product-normal">
                            Transport: {item.transport_type}
                          </span>
                          <br></br>
                          <span className="cart-product-normal">
                            Transport Charges: AED {item.transport}
                          </span>
                          <br></br>
                          <span className="cart-product-normal">
                            Booking Date: {item.date}
                          </span>
                          <br></br>
                          <br></br>
                        </Col>
                      </Row>
                    </Container>
                  );
                })}
                <Row style={{ marginTop: "40px" }}>
                  <Col ms={6} className="cart_total_div">
                    <span className="cart_total_text">Subtotal</span>
                  </Col>
                  <Col
                    ms={6}
                    className="cart_total_div"
                    style={{ justifyContent: "end" }}
                  >
                    <span className="cart_total_text">AED {getTotal()}</span>
                  </Col>
                </Row>
                <Row>
                  <Col ms={6} className="cart_total_div">
                    <span className="cart_total_text">Total</span>
                  </Col>
                  <Col
                    ms={6}
                    className="cart_total_div"
                    style={{ justifyContent: "end" }}
                  >
                    <span className="cart_total_text">AED {getTotal()}</span>
                  </Col>
                </Row>
                <Row>
                  <Col ms={6} className="cart_total_div">
                    <span className="cart_total_text">To Pay </span>
                  </Col>
                  <Col
                    ms={6}
                    className="cart_total_div"
                    style={{ justifyContent: "end" }}
                  >
                    <span className="cart_total_text">
                      <strong>AED {getTotal()}</strong>
                    </span>
                  </Col>
                </Row>
              </Container>
            </BrowserView>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
